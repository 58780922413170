/* eslint-disable no-unused-vars */
import { useEffect, useCallback, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useEthosModal } from "../../../../../contexts/ethos-modal-context";
import { useCustomer } from "../../../../../contexts/customer-context";
import { useEthosNotification } from "../../../../../contexts/ethos-notification-context";
import NewCustomer from "../../../../../components/customers/new-customer";
import "./relative-table.css";
const FamilySection = ({ dataToSubmit = {}, setDataToSubmit = () => {}, customer = null, isDisable = false }) => {
  const didMount = useRef(false);
  const { openSelectCustomerModal } = useEthosModal();
  const { getFilteredCustomers, getSingleCustomer } = useCustomer();
  const { handleError } = useEthosNotification();

  const [relatives, setRelatives] = useState(customer !== null ? customer.relatives : undefined);

  /* For Search Modal */
  const [relativesIds, setRelativeIds] = useState([]);
  const [relativesInfo, setRelativesInfo] = useState([]);
  const [isFirstRender, setIsFirstRender] = useState(true);
  /* For Add Modal */
  const [showNewCustomer, setShowNewCustomer] = useState(false);
  const [createCustomer, setCreateCustomer] = useState(null);

  /* Search for all customers */
  const loadCustomer = useCallback(
    async (search, customerID, limit, offset) => {
      try {
        const { data, error, length } = await getFilteredCustomers(search, customerID, limit, offset);
        if (error) {
          handleError(error);
          return;
        }
        return { data, length };
      } catch (error) {
        console.error(`Errore ${error}`);
      }
    },
    [getFilteredCustomers, handleError]
  );

  /* Search for the individual customer */
  const getRelativeInfo = useCallback(
    async (id) => {
      try {
        const { data, error } = await getSingleCustomer(id);

        if (error) {
          return { _id: "-1", first_name: "N.D.", last_name: "N.D.", cf: "N.D.", email: "N.D.", phone: "N.D." };
        }

        return {
          _id: data._id,
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email ? data.email : "N.D.",
          phone: data.phone ? data.phone : "N.D.",
        };
      } catch (error) {
        console.log(error);
      }
    },
    [getSingleCustomer]
  );

  /* Render the table the first time if relatives are already present  */
  useEffect(() => {
    if (relatives.length > 0 && isFirstRender) {
      const takeOnlyIds = relatives.map((item) => item.relativeId);
      setRelativeIds(takeOnlyIds);
      setIsFirstRender(false);
    }
  }, [isFirstRender, relatives]);

  useEffect(() => {
    didMount.current = false;
  }, [customer]);

  useEffect(() => {
    if (!didMount.current) {
      return (didMount.current = true);
    }

    const data = {
      ...dataToSubmit,
      relatives: relatives,
    };
    setDataToSubmit(data);
    // eslint-disable-next-line
  }, [relatives]);

  /* Gets the information to generate the table */
  useEffect(() => {
    const fetchAllRelativesInfo = async () => {
      const infoPromises = relativesIds.map((item) => getRelativeInfo(item));
      const results = await Promise.all(infoPromises);
      setRelativesInfo(results);
    };

    if (relativesIds.length > 0) {
      fetchAllRelativesInfo();
    }
  }, [getRelativeInfo, relativesIds]);

  /* Opens modal to search and select customers */
  const openSearchCustomerModal = async () => {
    openSelectCustomerModal("Assegna Familiare", "modal-xl", loadCustomer, relatives, customer._id, (selectedCustomers) => {
      const takeOnlyIDs = selectedCustomers.map((item) => item.relativeId);
      setRelativeIds(takeOnlyIDs);
      setRelatives(selectedCustomers);
    });
  };

  /* When it loses focus set the new relationship */
  const handleRelationChange = (relativeId, newRelation) => {
    const updatedRelatives = relatives.map((item) => {
      if (item.relativeId === relativeId) {
        return { ...item, relation: newRelation };
      }
      return item;
    });
    setRelatives(updatedRelatives);
  };

  /* Adds the newly created client to the table */
  const handleIdFromModal = (data) => {
    setRelativeIds([...relativesIds, data]);
    setRelatives([...relatives, { relativeId: data, relation: "" }]);
  };

  return (
    <>
      <div className="step-family">
        <div className="d-flex gap-3">
          <button
            onClick={() => {
              openSearchCustomerModal();
            }}
            className="btn btn-sm btn-primary"
            disabled={isDisable}
          >
            <FontAwesomeIcon className="mr-2" icon={faSearch} />
            Cerca Cliente
          </button>

          <button onClick={() => setShowNewCustomer(!showNewCustomer)} className="btn btn-sm btn-primary" disabled={isDisable}>
            <FontAwesomeIcon className="mr-2" icon={faPlus} />
            Aggiungi Cliente
          </button>
        </div>
        {relatives && relatives.length > 0 ? (
          <table className="table table-striped mt-3">
            <thead className="thead-dark">
              <tr>
                <th>
                  Parentela <span style={{ color: "red" }}>*</span>
                </th>
                <th>Nome</th>
                <th>Cognome</th>
                <th>Email</th>
                <th>Numero di Telefono</th>
              </tr>
            </thead>
            {relatives.length === relativesInfo.length && (
              <tbody>
                {relatives.map((item) => {
                  const relativeInfo = relativesInfo.find((customerInfo) => customerInfo._id === item.relativeId);
                  return (
                    <tr key={item.relativeId}>
                      <td>
                        <input
                          type="text"
                          id="relation"
                          name="relation"
                          placeholder="Parentela"
                          className={`form-control ${item.relation === "" ? "relative-error" : null}`}
                          style={{ textAlign: "right" }}
                          defaultValue={item.relation}
                          onChange={(e) => {
                            const value = e.target.value.trim()
                            handleRelationChange(item.relativeId, value);
                          }}
                        />
                      </td>
                      <td>{relativeInfo ? relativeInfo.first_name : "N.D."}</td>
                      <td>{relativeInfo ? relativeInfo.last_name : "N.D."}</td>
                      <td>{relativeInfo ? relativeInfo.email : "N.D."}</td>
                      <td>{relativeInfo ? relativeInfo.phone : "N.D."}</td>
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        ) : null}
      </div>

      {showNewCustomer ? (
        <NewCustomer setShowNewCustomer={setShowNewCustomer} setCustomers={setCreateCustomer} sendNewCustomerId={handleIdFromModal} />
      ) : null}
    </>
  );
};

export default FamilySection;
