import { useEffect } from "react";
import { InputNumber, InputTextArea, InputSelect, InputDate, InputText } from "../../../../../../ui-components/forms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { EnumQuestionOptionResponseType, Question } from "../../../../../../models";

type QuestionProps = {
  level: number;
  question: Question;
  readOnly: boolean;
  updateAnswer: (question: any) => void;
  setError: () => void;
  inputName: string;
  bankList?: { value: string; label: string }[];
};

const QuestionComponent = ({
  level = 0,
  question,
  readOnly = false,
  updateAnswer = () => {},
  setError = () => {},
  inputName,
  bankList,
}: QuestionProps) => {
  /**
   * Converte un array di opzioni in un formato utilizzabile da un componente Select.
   * @param {Array} opts - Array di opzioni.
   * @returns {Array} - Array di opzioni mappate.
   */
  const mapSelectOptions = (opts) => {
    return opts.map(function (o) {
      return {
        label: o,
        value: o,
      };
    });
  };

  /**
   * Aggiorna la risposta di un singolo elemento all'interno di un blocco di risposte.
   * @param {number} rIndex - Indice del blocco di risposte.
   * @param {number} order - Ordine dell'elemento nel blocco.
   * @param {any} val - Valore da aggiornare.
   */
  const setArrayResponse = (rIndex, order, val) => {
    const newVal = [...question.response];
    const block = newVal[rIndex].map((item) => {
      if (item.order === order) {
        return { ...item, response: val };
      }
      return item;
    });
    newVal[rIndex] = block;
    updateAnswer(newVal);
  };

  /**
   * Aggiunge un nuovo blocco di risposte.
   */
  const addArrayBlock = () => {
    const newVal = question.response ? [...question.response] : [];
    const optCopy = question.options.map((o) => ({ ...o, response: undefined }));
    newVal.push(optCopy);
    updateAnswer(newVal);
  };

  /**
   * Rimuove un blocco di risposte specificato dal suo indice.
   * @param {number} idx - Indice del blocco da rimuovere.
   */
  const removeArrayBlock = (idx) => {
    const newVal = question.response.filter((_, i) => i !== idx);
    updateAnswer(newVal);
  };

  /**
   * Utilizza useEffect per inizializzare la data di risposta se non esiste già una risposta e il tipo di risposta è "Date".
   */
/*   useEffect(() => {
    if (!question.response && question.responseType === "Date" && question.required === true) {
      updateAnswer(new Date());
    }
  }, [question, updateAnswer]); */

  return (
    <>
      {question.responseType === EnumQuestionOptionResponseType.String ||
        (question.responseType === EnumQuestionOptionResponseType.TextArea && (
          <InputTextArea
            externalSetError={setError}
            disabled={readOnly}
            name={inputName}
            isRequired={question.required}
            onChange={updateAnswer}
            defaultValue={question.response}
            label={question.question}
          />
        ))}
      {question.responseType === EnumQuestionOptionResponseType.Text && (
        <InputText
          externalSetError={setError}
          disabled={readOnly}
          name={inputName}
          isRequired={question.required}
          onChange={updateAnswer}
          defaultValue={question.response}
          label={question.question}
        />
      )}
      {question.responseType === EnumQuestionOptionResponseType.Select && (
        <InputSelect
          disabled={readOnly}
          name={inputName}
          isRequired={question.required}
          label={question.question}
          defaultValue={question.response}
          data={mapSelectOptions(question.options)}
          onChange={updateAnswer}
        />
      )}
      {question.responseType === EnumQuestionOptionResponseType.SelectBank && (
        <InputSelect
          disabled={readOnly}
          name={inputName}
          isRequired={question.required}
          label={question.question}
          defaultValue={question.response}
          data={bankList}
          onChange={updateAnswer}
        />
      )}
      {question.responseType === EnumQuestionOptionResponseType.Money && (
        <InputNumber
          type="number"
          externalSetError={setError}
          disabled={readOnly}
          name={inputName}
          isRequired={question.required}
          price={true}
          onChange={updateAnswer}
          step={"0.1"}
          defaultValue={question.response}
          label={question.question}
        />
      )}
      {question.responseType === EnumQuestionOptionResponseType.Number && (
        <InputNumber
          type="number"
          externalSetError={setError}
          disabled={readOnly}
          name={inputName}
          isRequired={question.required}
          onChange={updateAnswer}
          defaultValue={question.response}
          label={question.question}
          step={"0.1"}
          count={true}
        />
      )}
      {question.responseType === EnumQuestionOptionResponseType.Date && (
        <InputDate
          disabled={readOnly}
          name={inputName}
          isRequired={question.required}
          onChange={updateAnswer}
          defaultValue={question.response}
          label={question.question}
        />
      )}
      {question.responseType === EnumQuestionOptionResponseType.Array && (
        <>
          {(!question.response || !question.response.length) && <h5 className="text-center mt-3">{question.question}</h5>}
          {question.response &&
            question.response.map((r, rIndex) => (
              <span key={"h_" + level + "_" + question.order + "_" + rIndex}>
                <h5 className="text-center mt-3">
                  {question.question} - Blocco #{rIndex + 1}{" "}
                  {!readOnly && (
                    <span style={{ cursor: "pointer" }} onClick={() => removeArrayBlock(rIndex)}>
                      <FontAwesomeIcon className="text-danger" icon={faTimes} />
                    </span>
                  )}
                </h5>
                {r.map((r2) => (
                  <span key={"q_" + level + "_" + question.order + "_" + rIndex + "_" + r2.order}>
                    <div className="my-3">
                      <QuestionComponent
                        setError={setError}
                        readOnly={readOnly}
                        inputName={inputName + "_q_" + level + "_" + question.order + "_" + rIndex + "_" + r2.order}
                        level={level + 1}
                        question={r2}
                        updateAnswer={(val) => setArrayResponse(rIndex, r2.order, val)}
                      />
                    </div>
                  </span>
                ))}
              </span>
            ))}
          {!readOnly ? (
            <div className="row mt-2">
              <div className="col-8 offset-2 text-center">
                <button onClick={addArrayBlock} className="btn btn-primary mt-3">
                  Aggiungi blocco
                </button>
              </div>
            </div>
          ) : null}
        </>
      )}
    </>
  );
};

export default QuestionComponent;
