import React, { useEffect, useState } from "react";
import QuestionComponent from "./question/question.component";
import { useBank } from "../../../../../contexts/banks-context";
import { useEthosNotification } from "../../../../../contexts/ethos-notification-context";

const SurveyStepElement = ({ survey, readOnly = false, updateSurvey = () => {}, setUnsavedChanges = () => {}, overrideClass }) => {
  const [mySurvey, setMySurvey] = useState(survey);
  const [answersWithError] = useState([]);
  const { getBanks } = useBank();
  const { handleError } = useEthosNotification();
  const [banks, setBanks] = useState([]);
  const doUpdateSurvey = () => {
    if (answersWithError && answersWithError.length) {
      return;
    }
    updateSurvey(mySurvey);
    setUnsavedChanges(false);
  };

  useEffect(() => {
    setMySurvey([...survey]);
  }, [survey]);

  const setResponse = (outerIndex, innerIndex, value) => {
    let surveyCopy = [...mySurvey];
    surveyCopy[outerIndex].questions[innerIndex].response = value;
    setMySurvey(surveyCopy);
    setUnsavedChanges(true);
  };

  const setError = (outerIndex, innerIndex, value) => {
    /*  let answersCopy = [...answersWithError];
    answersCopy = answersCopy.filter((x) => x !== `${outerIndex}-${innerIndex}`);
    if (value) {
      answersCopy.push(`${outerIndex}-${innerIndex}`);
    }
    setAnswersWithError(answersCopy); */
  };

  useEffect(() => {
    const initBanks = async () => {
      const { data, error } = await getBanks(null, null, null, null);
      if (error) {
        handleError(error);
        return;
      }

      const response = data.data.map((item) => {
        return {
          value: item.name,
          label: item.name,
        };
      });
      setBanks(response);
    };

    initBanks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="row mb-3">
        <div className="col-12">
          {mySurvey.map((surveyItem, outerIndex) => {
            return (
              <div key={"survey_" + surveyItem.order} className="row">
                <div className={`${overrideClass ? overrideClass.survey.class : "col-8 offset-2 text-center my-3"}`}>
                  <h3 className="text-center mt-1">{surveyItem.title}</h3>
                  <div className={overrideClass ? overrideClass.row.class : ""}>
                    {surveyItem.questions.map((question, innerIndex) => {
                      return (
                        <div className={`my-3 ${overrideClass ? overrideClass.input.class : ""}`} key={innerIndex}>
                          <QuestionComponent
                            key={"q_" + question.order}
                            setError={(err) => {
                              setError(outerIndex, innerIndex, err);
                            }}
                            readOnly={readOnly}
                            inputName={"q_" + question.order}
                            question={question}
                            updateAnswer={(val) => {
                              setResponse(outerIndex, innerIndex, val);
                            }}
                            bankList={banks}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
                {outerIndex < mySurvey.length - 1 && <hr className="my-3" />}
              </div>
            );
          })}
        </div>
      </div>
      {!readOnly ? (
        <div className="row">
          <div className="col-8 offset-2 text-center">
            <div className="btn-group w-100" role="group">
              {answersWithError && answersWithError.length ? (
                <button className="btn btn-primary disabled">Salva Questionario</button>
              ) : (
                <button onClick={() => doUpdateSurvey()} className="btn btn-primary">
                  Salva Questionario
                </button>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default SurveyStepElement;
