import { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import "./customer.css";

// sections
import OthersIncomeYearly from "./steps/others-income-yearly";
import PatrimonialSection from "./steps/patrimonial-section";
import IncomesSection from "./steps/incomes-section";
import ProfessionSection from "./steps/profession-section";
import RegistrySection from "./steps/registry-section";
import ServicesSection from "./steps/services-section/services-section";
import FinancialSection from "./steps/financial-section";

const StepForm = styled.section``;

const FormCustomer = ({
  customerChanged,
  isDisable = false,
  customer = null,
  services = [],
  users = [],
  serviceCustomerList = [],
  handleSave,
  updateServiceCustomer = () => {},
  activateServiceCustomer = () => {},
  addServiceCustomer = () => {},
  delServiceCustomer = () => {},
  sendTranchePaymentLink = () => {},
}) => {
  const [stepOpened, setStepOpened] = useState("registry");
  const [professionType, setProfessionType] = useState(customer !== null ? customer.profession.type_prof : undefined);
  const [monthlyIncomeNet, setMonthlyIncomeNet] = useState(customer !== null ? customer.profession.monthlyIncomeNet : 0.0);
  const [RDC, setRDC] = useState(customer !== null ? customer.profession.RDC : undefined);
  const [othersIncome, setOthersIncome] = useState(customer !== null ? customer.profession.othersIncome : 0.0);
  const didMount = useRef(false);
  const [dataToSubmit, setDataToSubmitInternal] = useState(
    customer === null
      ? {
          status: "PROSPECT",
        }
      : {
          first_name: customer.first_name,
          last_name: customer.last_name,
          phone: customer.phone,
          email: customer.email,
          pec: customer.pec,
          isContractual: customer.isContractual,
          status: customer.status,
          citybirth: customer.citybirth,
          datebirth: customer.datebirth,
          cf: customer.cf,
          piva: customer.piva,
          address: customer.address,
          postcode: customer.postcode,
          city: customer.city,
          prov: customer.prov,
          note: customer.note,

          profession: customer.profession,
          familiares: customer.familiares,
          relatives: customer.relatives,
          cqs: customer.cqs,
          loands: customer.loands,
          carte: customer.carte,
          injuctionDecree: customer.injuctionDecree,
          injuctionDecreeNote: customer.injuctionDecreeNote,
          mutui: customer.mutui,
          taxCollection: customer.taxCollection,
          othersIncomeMonthly: customer.othersIncomeMonthly,
          incomes: customer.incomes,
          house: customer.house,
          property: customer.property,
          stock: customer.stock,
          society: customer.society,
          notesPatri: customer.notesPatri,
          monthlyIncome: customer.monthlyIncome,
          thirdForeclosureBeneficiary: customer.thirdForeclosureBeneficiary,
          thirdForeclosureImport: customer.thirdForeclosureImport,
          spouseForeclosureBeneficiary: customer.spouseForeclosureBeneficiary,
          spouseForeclosureImport: customer.spouseForeclosureImport,
          monthlySavingsIntermediary: customer.monthlySavingsIntermediary,
          monthlySavingsImport: customer.monthlySavingsImport,
          othersYearlyIncome: customer.othersYearlyIncome,
          othersYearlyIncomeNote: customer.othersYearlyIncomeNote,
        }
  );

  const setDataToSubmit = (data) => {
    setDataToSubmitInternal(data);
  };

  const isNew = customer === null;

  const { register, handleSubmit } = useForm({});

  useEffect(() => {
    didMount.current = false;
  }, [customer]);

  useEffect(() => {
    if (!didMount.current) {
      return (didMount.current = true);
    }
    customerChanged();
  }, [dataToSubmit, customerChanged]);

  const isRelationValid = () => {
    if (dataToSubmit.relatives && dataToSubmit.relatives.length > 0) {
      return dataToSubmit.relatives.some((item) => !item.relation);
    }
  };

  const onSubmit = async () => {
    let obj = dataToSubmit;
    // fix
    for (var p in obj) {
      if (obj[p] === "" || !obj[p]) {
        obj[p] = undefined;
      }
    }

    await handleSave(obj);
  };

  const newServiceCustomer = (newServiceCustomer) => {
    addServiceCustomer(newServiceCustomer);
  };

  return (
    <>
      {!isNew && (
        <ul className="nav nav-tabs mb-3">
          <li className="nav-item gradient-bg">
            <button onClick={() => setStepOpened("registry")} className={"nav-link" + (stepOpened === "registry" ? " active" : "")}>
              Anagrafica
            </button>
          </li>
          {!isNew && (
            <>
              <li className="nav-item gradient-bg">
                <button onClick={() => setStepOpened("profession")} className={"nav-link" + (stepOpened === "profession" ? " active" : "")}>
                  Reddito-Patrimonio
                </button>
              </li>
              <li className="nav-item gradient-bg">
                <button onClick={() => setStepOpened("services")} className={"nav-link" + (stepOpened === "services" ? " active" : "")}>
                  Servizi e costi
                </button>
              </li>
            </>
          )}
        </ul>
      )}

      <div style={{ padding: "1em", minHeight: "40vh" }}>
        {stepOpened !== "services" && (
          <div className="alert alert-info">
            <strong>TUTTI I CAMPI SEGUITI DA * SONO OBBLIGATORI</strong>
          </div>
        )}

        {stepOpened === "registry" && (
          <StepForm style={{ display: stepOpened === "registry" ? "block" : "none" }}>
            <RegistrySection
              register={register}
              dataToSubmit={dataToSubmit}
              setDataToSubmit={setDataToSubmit}
              customer={customer}
              isDisable={isDisable}
            />
          </StepForm>
        )}

        {stepOpened === "services" && (
          <StepForm>
            <ServicesSection
              users={users}
              customer={customer}
              serviceCustomerList={serviceCustomerList}
              updateServiceCustomer={updateServiceCustomer}
              activateServiceCustomer={activateServiceCustomer}
              delServiceCustomer={delServiceCustomer}
              services={services}
              addServiceCustomer={newServiceCustomer}
              doSendTranchePaymentLink={sendTranchePaymentLink}
            />
          </StepForm>
        )}

        {stepOpened === "profession" && (
          <StepForm>
            <h2>Lavoro</h2>

            <ProfessionSection
              dataToSubmit={dataToSubmit}
              setDataToSubmit={setDataToSubmit}
              customer={customer}
              isDisable={isDisable}
              professionType={professionType}
              monthlyIncomeNet={monthlyIncomeNet}
              RDC={RDC}
              othersIncome={othersIncome}
              setProfessionType={setProfessionType}
              setMonthlyIncomeNet={setMonthlyIncomeNet}
              setRDC={setRDC}
              setOthersIncome={setOthersIncome}
            />
            
            <h2>Finanze</h2>
            <FinancialSection dataToSubmit={dataToSubmit} setDataToSubmit={setDataToSubmit} customer={customer} isDisable={isDisable} />

            <h2>Reddito</h2>
            <IncomesSection
              dataToSubmit={dataToSubmit}
              setDataToSubmit={setDataToSubmit}
              professionType={professionType}
              monthlyIncomeNet={monthlyIncomeNet}
              RDC={RDC}
              othersIncome={othersIncome}
              customer={customer}
              isDisable={isDisable}
            />

            <h2>Patrimonio</h2>
            <PatrimonialSection dataToSubmit={dataToSubmit} setDataToSubmit={setDataToSubmit} customer={customer} isDisable={isDisable} />

            <h2>Altri Impegni Mensili?</h2>
            <OthersIncomeYearly dataToSubmit={dataToSubmit} setDataToSubmit={setDataToSubmit} customer={customer} isDisable={isDisable} />
          </StepForm>
        )}

        {/* {
					(stepOpened === 'financial') &&
					<StepForm>
						<FinancialSection dataToSubmit={dataToSubmit} setDataToSubmit={setDataToSubmit} customer={customer} isDisable={isDisable} />
					</StepForm>
				} */}

        {/* {(stepOpened === 'incomes') &&
					<StepForm >
						<IncomesSection
							dataToSubmit={dataToSubmit}
							setDataToSubmit={setDataToSubmit}
							professionType={professionType}
							monthlyIncomeNet={monthlyIncomeNet}
							RDC={RDC}
							othersIncome={othersIncome}
							customer={customer}
							isDisable={isDisable} />
					</StepForm>
				} */}

        {/* {
					(stepOpened === 'patrimonial') &&
					<StepForm>
						<PatrimonialSection dataToSubmit={dataToSubmit} setDataToSubmit={setDataToSubmit} customer={customer} isDisable={isDisable} />
					</StepForm>
				} */}

        {/* {
					(stepOpened === 'other_incomes') &&
					<StepForm>
						<OthersIncomeYearly dataToSubmit={dataToSubmit} setDataToSubmit={setDataToSubmit} customer={customer} isDisable={isDisable} />
					</StepForm>
				} */}
      </div>
      {stepOpened !== "services" ? (
        <>
          <hr />
          <div>
            <button
              disabled={isRelationValid()}
              onClick={handleSubmit(onSubmit)}
              className={stepOpened === 6 ? "btn btn-primary mx-2 w-100" : "btn btn-primary w-100"}
            >
              Salva
            </button>
          </div>
        </>
      ) : null}
    </>
  );
};

export default FormCustomer;
