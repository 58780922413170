import { useEffect, useState, useRef } from 'react';

import { BottomPreview } from '../../../../custom-components';
import { InputNumber, InputTextArea } from '../../../../forms';
import { BanksProvider } from '../../../../../contexts/banks-context';

import CQS from './cqs-section';
import Carte from './credit-card-section';
import Loans from './loans-section';
import Mutui from './mutui-section';
import TaxCollections from './tax-collection-section';

const FinancialSection = ({ dataToSubmit = {}, setDataToSubmit = () => { }, customer = null, isDisable = false }) => {
	const [haveCQS, setHaveCQS] = useState(customer !== null ? customer.cqs.haveCQS : false);
	const [cqsItems, setCqsItems] = useState(customer !== null ? customer.cqs.items : []);
	const [loands, setLoands] = useState(customer !== null ? customer.loands.haveLoands : false);
	const [loandsItems, setLoandsItems] = useState(customer !== null ? customer.loands.items : []);
	const [carte, setCarte] = useState(customer !== null ? customer.carte.haveCarte : false);
	const [carteItems, setCarteItems] = useState(customer !== null ? customer.carte.items : []);
	const [mutui, setMutui] = useState(customer !== null ? customer.mutui.haveMutui : false);
	const [mutuiItems, setMutuiItems] = useState(customer !== null ? customer.mutui.items : []);
	const [decree, setDecree] = useState(customer !== null ? customer.injuctionDecree : false);
	const [decreeNote, setDecreeNote] = useState(customer !== null ? customer.injuctionDecreeNote : '');
	const [taxCollection, setTaxCollection] = useState(customer !== null ? customer.taxCollection.haveTaxCollection : false);
	const [taxCollectionItems, setTaxCollectionItems] = useState(customer !== null ? customer.taxCollection.items : []);
	const [otherMonthlyIncome, setOtherMonthlyIncome] = useState(customer !== null ? customer.othersIncomeMonthly.haveOthers : false);
	const [rentals, setRentals] = useState(customer !== null ? customer.othersIncomeMonthly.rentals : 0);
	const [foreclosure, setForeclosure] = useState(customer !== null ? customer.othersIncomeMonthly.foreclosure : 0);
	const [foreclosureSpoused, setForeclosureSpoused] = useState(customer !== null ? customer.othersIncomeMonthly.foreclosureSpoused : 0);
	const didMount = useRef(false);

	useEffect(() => {
		didMount.current = false;
	}, [customer]);

	useEffect(() => {
		if (!didMount.current) {
			return didMount.current = true;
		}
		const data = {
			...dataToSubmit,
			cqs: {
				haveCQS: haveCQS,
				items: cqsItems
			},
			loands: {
				haveLoands: loands,
				items: loandsItems
			},
			carte: {
				haveCarte: carte,
				items: carteItems
			},
			injuctionDecree: decree,
			injuctionDecreeNote: decreeNote,
			mutui: {
				haveMutui: mutui,
				items: mutuiItems
			},
			taxCollection: {
				haveTaxCollection: taxCollection,
				items: taxCollectionItems
			},
			othersIncomeMonthly: {
				haveOthers: otherMonthlyIncome,
				rentals: rentals,
				foreclosure: foreclosure,
				foreclosureSpoused: foreclosureSpoused,
			},
		}
		setDataToSubmit(data);

		// eslint-disable-next-line
	}, [haveCQS, cqsItems, loands, loandsItems, mutui, mutuiItems, decree, decreeNote, taxCollection, taxCollectionItems, otherMonthlyIncome, rentals, foreclosure, foreclosureSpoused, carte, carteItems]);

	const handleGetTotalDebit = () => {
		let total = 0;
		let i = 0;

		for (i = 0; i < mutuiItems.length; i++) {
			total += parseFloat(mutuiItems[i].debit)
		}

		for (i = 0; i < taxCollectionItems.length; i++) {
			total += parseFloat(taxCollectionItems[i].debit)
		}

		total += parseFloat((parseFloat(rentals) + parseFloat(foreclosure) + parseFloat(foreclosureSpoused)) * 12);

		return total;
	}

	return (
		<div className="step-financial">
			<BanksProvider>
				<CQS
					disabled={isDisable}
					haveCQS={haveCQS}
					cqsItems={cqsItems}
					setHaveCQS={setHaveCQS}
					setCqsItems={setCqsItems} />

				<hr />

				<Loans
					disabled={isDisable}
					haveLoans={loands}
					loansItems={loandsItems}
					setHaveLoans={setLoands}
					setLoansItems={setLoandsItems}
					handleGetTotalDebit={handleGetTotalDebit} />

				<hr />

				<Carte
					disabled={isDisable}
					haveLoans={carte}
					loansItems={carteItems}
					setHaveLoans={setCarte}
					setLoansItems={setCarteItems}
					handleGetTotalDebit={handleGetTotalDebit} />

				<hr />

				<div className="decree">
					<label htmlFor="decree" className="mb-2"><input disabled={isDisable} onChange={() => setDecree(!decree)} type="checkbox" defaultChecked={decree} name="decree" id="decree" /> <span className="mx-2"> Decreto Ingiuntivo?</span></label>
					<br />
					{decree ? <InputTextArea defaultValue={decreeNote} name="decreenote" label="Note Decreto" onChange={setDecreeNote} /> : null}
				</div>

				<hr />

				<Mutui
					disabled={isDisable}
					haveMutui={mutui}
					mutuiItems={mutuiItems}
					setHaveMutui={setMutui}
					setMutuiItems={setMutuiItems}
					handleGetTotalDebit={handleGetTotalDebit}
				/>
			</BanksProvider>

			<hr />

			<TaxCollections
				disabled={isDisable}
				haveTaxCollection={taxCollection}
				taxCollectionItems={taxCollectionItems}
				setHaveTaxCollection={setTaxCollection}
				setTaxCollectionItems={setTaxCollectionItems}
				handleGetTotalDebit={handleGetTotalDebit} />

			<hr />

			<div className="otherMonthlyIncome">
				<label htmlFor="otherMonthlyIncome" className="mb-2"><input disabled={isDisable} onChange={() => setOtherMonthlyIncome(!otherMonthlyIncome)} type="checkbox" defaultChecked={otherMonthlyIncome} name="otherMonthlyIncome" id="otherMonthlyIncome" /> <span className="mx-2"> Altri Impegni Mensili?</span></label>
				<br />
				{otherMonthlyIncome ?
					<>
						<div className="row">
							<div className="col-md-4">
								<InputNumber price={true} validation={null} defaultValue={rentals} type="number" step="0.01" label="Affitti, Redditi Passivi" name="rentals" onChange={setRentals} />
								<BottomPreview>Anno: &euro; {parseFloat(rentals * 12).toFixed(2)}</BottomPreview>
							</div>
							<div className="col-md-4">
								<InputNumber price={true} validation={null} defaultValue={foreclosure} type="number" step="0.01" label="Pignoramento casa o terzi" name="foreclosure" onChange={setForeclosure} />
								<BottomPreview>Anno: &euro; {parseFloat(foreclosure * 12).toFixed(2)}</BottomPreview>
							</div>
							<div className="col-md-4">
								<InputNumber price={true} validation={null} defaultValue={foreclosureSpoused} type="number" step="0.01" label="Pignoramenti coniuge" name="foreclosureSpoused" onChange={setForeclosureSpoused} />
								<BottomPreview>Anno: &euro; {parseFloat(foreclosureSpoused * 12).toFixed(2)}</BottomPreview>
							</div>
						</div>
					</>
					: null}
			</div>

			<hr />

			<p style={{ fontSize: 18, fontWeight: 'bold' }}>Totale Esposizione Debitoria: &euro; {parseFloat(handleGetTotalDebit()).toFixed(2)}</p>

		</div>
	)
}

export default FinancialSection;