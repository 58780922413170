import { useEffect, useState } from 'react';
import moment from 'moment';

import { NewPageWrapper, NewPageWrapperCopy } from '../../../../custom-components';
import { InputText, InputNumber, InputDate, InputSelect, InputTextArea } from '../../../../forms';
import { useBank } from '../../../../../contexts/banks-context';

const AddCQS = ({ setOpenAddCQS, handleSaveItems }) => {
	const { getBanksForPractice } = useBank();
	const [nContract, setNrContract] = useState(0);
	const [bank, setBank] = useState(null);
	const [branch, setBranch] = useState(null);
	const [dateStart, setDateStart] = useState(null);
	const [dateEnd, setDateEnd] = useState(null);
	const [dateClose, setDateClose] = useState(null);
	const [pecBank, setPecBank] = useState(null);
	const [notes, setNotes] = useState(null);
	const [haveDebit, setHaveDebit] = useState(false);
	const [importLoan, setImportLoan] = useState(0);
	const [nInstallments, setNInstallments] = useState(0);
	const [singleInstallmentImport, setSingleInstallmentImport] = useState(0);
	const [tan, setTan] = useState(0);
	const [taeg, setTaeg] = useState(0);
	const [banks, setBanks] = useState([]);

	const [currentPecList,setCurrentPecList] = useState([])

	useEffect(() => {
		const getBanks = async () => {
			const { data, error } = await getBanksForPractice();

			if (error !== null) return alert('Error to load banks');

			const dataToObj = data.map((item, index) => {
				return {
					index: index,
					pec: item.pec.map((pecItem,pecIndex)=>{return {index:pecIndex,pec:pecItem,value:pecItem,label:pecItem}}),
					value: item.name,
					label: item.name
				}
			});

			setBanks(dataToObj);
		}

		getBanks();
	}, [getBanksForPractice]);

	const handleAddItem = async () => {
		const data = {
			nContract,
			bank,
			branch,
			dateStart,
			dateEnd,
			dateClose,
			pecBank,
			notes,
			importLoan,
			nInstallments,
			singleInstallmentImport,
			tan,
			taeg,
			haveDebit,
		}

		await handleSaveItems(data);
		setOpenAddCQS(false);
	}

	const handleOnChangeBank = async (value) => {
		const pec = banks.filter(item => item.label === value)[0].pec;
		setBank(value);
		setCurrentPecList(pec);
	}

	const handleOnChangePec = async (value) => {
		setPecBank(value)
	}

	const handleOnChangeDebit = async (value) => {
		setHaveDebit(value === 'SI' ? false : true);
	}

	return (
		<NewPageWrapper>
			<NewPageWrapperCopy>
				<div className="top-page">
					<h3 className="top-page-title">Nuovo CQS</h3>
					<button className="close-button" onClick={() => setOpenAddCQS(false)}>&times;</button>
				</div>

				<hr />

				<div className="row">
					<div className="col-md-4">
						<InputText defaultValue={nContract} name="nContract" label="N. Contratto" isRequired={false} onChange={setNrContract} />
					</div>

					<div className="col-md-4">
						<InputSelect defaultValue={bank} name="bank" data={banks} label="Finanziaria/Banca" isRequired={false} onChange={handleOnChangeBank} />
					</div>

					<div className="col-md-4">
						<InputText defaultValue={branch} name="branch" label="Filiale" isRequired={false} onChange={setBranch} />
					</div>
				</div>

				<div className="row mt-2">
					<div className="col-md-4">
						<InputDate defaultValue={dateStart} name="dateStart" label="Data inizio Contratto" isRequired={false} onChange={setDateStart} />
					</div>

					<div className="col-md-4">
						<InputDate defaultValue={dateEnd} name="dateEnd" label="Data fine Contratto" isRequired={false} onChange={setDateEnd} />
					</div>

					<div className="col-md-4">
						<InputDate defaultValue={dateClose} name="dateClose" label="Data chiusura Contratto" isRequired={false} onChange={setDateClose} />
					</div>
				</div>

				<div className="row mt-2">
					<div className="col-md-4">
						<InputSelect defaultValue={pecBank} name="pecBank" label="PEC Banca" data={currentPecList} onChange={handleOnChangePec} />
					</div>

					<div className="col-md-4">
						<InputNumber validation={null} type="number" step="0.01" isRequired={false} price={true} name="importLoan" label="Importo Prestito" onChange={setImportLoan} />
					</div>

					<div className="col-md-4">
						<InputNumber validation={null} type="number" step="1" isRequired={false} name="nInstallments" label="Nr. Rate" onChange={setNInstallments} />
					</div>
				</div>

				<div className="row mt-2">
					<div className="col-md-4">
						<InputNumber validation={null} type="number" step="0.01" isRequired={false} price={true} name="singleInstallmentImport" label="Importo Singola Rata" onChange={setSingleInstallmentImport} />
					</div>

					<div className="col-md-4">
						<InputNumber validation={null} type="number" step="0.01" isRequired={false} perc={true} name="tan" label="TAN" onChange={setTan} />
					</div>

					<div className="col-md-4">
						<InputNumber validation={null} type="number" step="0.01" isRequired={false} perc={true} name="taeg" label="TAEG" onChange={setTaeg} />
					</div>
				</div>

				<div className="row mt-2">
					<div className="col-md-4">
						<InputSelect defaultValue={''} name="haveDebit" data={[{ value: 'SI', label: 'SI' }, { value: 'NO', label: 'NO' }]} label="Il Cliente sta riuscendo a pagare?" isRequired={false} onChange={handleOnChangeDebit} />
					</div>

					<div className="col-md-12 mt-2">
						<InputTextArea defaultValue={notes} name="notes" label="Note" onChange={setNotes} />
					</div>
				</div>

				<hr />

				<input onClick={handleAddItem} type="button" value="Aggiungi" className="btn btn-primary" />

			</NewPageWrapperCopy>
		</NewPageWrapper >
	)
}

const CQS = ({ disabled = false, cqsItems = [], haveCQS = false, setHaveCQS, setCqsItems }) => {
	const [openAddCQS, setOpenAddCQS] = useState(false);
	const [viewedItems, setViewdItems] = useState(null);

	const handleSaveItems = async (data) => {
		setCqsItems([...cqsItems, data]);
	}

	useEffect(() => {
		const handleRemove = (item) => {
			const index = cqsItems.indexOf(item);
			setCqsItems(items => items.filter((_val, i) => i !== index));
		}

		let data = [];

		cqsItems.map((item, i) => {
			data.push(
				<tr key={i}>
					<td>{item.nContract}</td>
					<td>{item.bank}</td>
					<td>{item.branch}</td>
					<td>{moment(item.dateStart).format('DD/MM/YYYY')}</td>
					<td>{moment(item.dateEnd).format('DD/MM/YYYY')}</td>
					<td>{moment(item.dateClose).format('DD/MM/YYYY')}</td>
					<td><button disabled={disabled} className="close-button d-block ms-auto" type="button" onClick={() => handleRemove(item)}>Rimuovi</button></td>
				</tr>
			);
			return {};
		})

		setViewdItems(data);
	}, [cqsItems, disabled, setCqsItems]);

	return (
		<>
			<div className="d-flex flex-columns align-items-center">
				<label htmlFor="cqs" className="mb-2"><input disabled={disabled} onChange={() => setHaveCQS(!haveCQS)} type="checkbox" defaultChecked={haveCQS} name="cqs" id="cqs" /> <span className="mx-2"> CQS?</span></label>
				{haveCQS && cqsItems.length < 5 && !disabled ? <button onClick={() => setOpenAddCQS(!openAddCQS)} type="button" className="btn btn-primary ms-auto">Aggiungi CQS</button> : null}
			</div>

			{openAddCQS ? <AddCQS setOpenAddCQS={setOpenAddCQS} handleSaveItems={handleSaveItems} /> : null}

			{cqsItems.length > 0 ?
				<div className="cqs-items">
					<hr />

					<table className="table">
						<thead>
							<tr>
								<th>Nr. Contratto</th>
								<th>Banca</th>
								<th>Finanziaria</th>
								<th>Data Inizio</th>
								<th>Data Fine</th>
								<th>Data Chiusura</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{
								viewedItems
							}
						</tbody>
					</table>
				</div>
				: null}
		</>
	)
}

export default CQS;