import React from 'react';
import { Switch, Route } from 'react-router-dom';

import EditCustomer from '../components/customers/edit-customer/edit-customer';
import ListCustomers from '../components/customers/list-customers/list-customers';
import { UserProvider } from '../contexts/user-context';
import { CustomerProvider } from '../contexts/customer-context';
import { ServiceProvider } from '../contexts/service-context';
import { ServiceCustomerProvider } from '../contexts/service-customer-context';
import { MemosProvider } from '../contexts/memo-context';
import { AuthProvider } from '../contexts/auth-context';
import { ActivitiesProvider } from '../contexts/activities-context';
import { FilesProvider } from '../contexts/files-context';
import { ConfigurationsContextProvider } from '../contexts/configurations-context';

const Customers = ({ match }) => {
	return (
		<div className="customers-page">
			<CustomerProvider>
				<UserProvider>
					<ServiceProvider>
						<FilesProvider>
							<ServiceCustomerProvider>
								<MemosProvider>
									<AuthProvider>
										<ActivitiesProvider>
											<ConfigurationsContextProvider>
												<Switch>
													<Route exact path={`${match.path}`} component={ListCustomers} />
													<Route exact path={`${match.path}/:id`} component={EditCustomer} />
												</Switch>
											</ConfigurationsContextProvider>
										</ActivitiesProvider>
									</AuthProvider>
								</MemosProvider>
							</ServiceCustomerProvider>
						</FilesProvider>
					</ServiceProvider>
				</UserProvider>
			</CustomerProvider>
		</div>
	)
}

export default Customers;