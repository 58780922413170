export enum EnumQuestionOptionResponseType {
  "String" = "String",
  "Array" = "Array",
  "Select" = "Select",
  "SelectBank" = "SelectBank",
  "Date" = "Date",
  "Money" = "Money",
  "Number" = "Number",
  "Text" = "Text",
  "TextArea" = "TextArea",
}

export interface QuestionOption {
  order: number;
  question: string;
  required: boolean;
  response: string;
  responseType: EnumQuestionOptionResponseType;
}

export interface Question {
  options: QuestionOption[];
  order: number;
  question: string;
  required: boolean;
  response: any;
  responseType: EnumQuestionOptionResponseType;
}
