import React, { useEffect, useState } from 'react';

import { NewPageWrapper, NewPageWrapperCopy } from '../../../../../custom-components';
import { InputText, InputNumber } from '../../../../../forms';

const AddHouse = ({ setOpenAddHouse, handleSaveItems }) => {
	const [address, setAddress] = useState('');
	const [comune, setComune] = useState('');
	const [city, setCity] = useState('');
	const [mqs, setMqs] = useState(0);
	const [status, setStatus] = useState('');
	const [value, setValue] = useState(0);
	const [ipo, setIpo] = useState(false);

	const handleAddItem = async () => {
		const data = {
			address,
			comune,
			city,
			mqs,
			status,
			value,
			ipo
		}

		await handleSaveItems(data);
		setOpenAddHouse(false);
	}

	return (
		<NewPageWrapper>
			<NewPageWrapperCopy>
				<div className="top-page">
					<h3 className="top-page-title">Nuovo Casa</h3>
					<button className="close-button" onClick={() => setOpenAddHouse(false)}>&times;</button>
				</div>

				<hr />

				<div className="row">
					<div className="col-md-3">
						<InputText label="Indirizzo" name="address" onChange={setAddress} />
					</div>
					<div className="col-md-3">
						<InputText label="Comune" name="comune" onChange={setComune} />
					</div>
					<div className="col-md-3">
						<InputText label="Città" name="city" onChange={setCity} />
					</div>
					<div className="col-md-3">
						<InputNumber validation={null} type="number" defaultValue={mqs} label="Metri Quadri" name="mqs" step="1" onChange={setMqs} />
					</div>
				</div>
				<div className="row">
					<div className="col-md-3">
						<InputText label="Stato Immobile" name="status" onChange={setStatus} />
					</div>
					<div className="col-md-3">
						<InputNumber price={true} validation={null} type="number" defaultValue={value} label="Valore Presunto" name="mqs" step="0.01" onChange={setValue} />
					</div>
					<div className="col-md-3">
						<label htmlFor="boh" className="d-block">&nbsp;</label>
						<label htmlFor="ipo"><input type="checkbox" name="ipo" id="ipo" onChange={() => setIpo(!ipo)} /> Ipotetica?</label>
					</div>
				</div>

				<hr />

				<input onClick={handleAddItem} type="button" value="Aggiungi" className="btn btn-primary" />

			</NewPageWrapperCopy>
		</NewPageWrapper>
	)
}

const House = ({ disabled = false, houseItems = [], haveHouse = false, setHaveHouse = () => {}, setHouseItems = () => {}, handleGetTotalPatrimonial = () => {} }) => {
	const [openAddHouse, setOpenAddHouse] = useState(false);
	const [viewedItems, setViewdItems] = useState(null);

	const handleSaveItems = async (data) => {
		setHouseItems([...houseItems, data]);
		handleGetTotalPatrimonial();
	}

	useEffect(() => {
		const handleRemove = (item) => {
			const index = houseItems.indexOf(item);
			setHouseItems(items => items.filter((_val, i) => i !== index));
		}
		
		let data = [];

		houseItems.map((item, i) => {
			data.push(
				<tr key={i}>
					<td>{item.address}</td>
					<td>{item.comune}</td>
					<td>{item.city}</td>
					<td>{item.mqs}</td>
					<td>{item.status}</td>
					<td>{item.value}</td>
					<td>{item.ipo ? 'SI' : 'NO' }</td>
					<td><button disabled={disabled} className="close-button d-block ms-auto" type="button" onClick={() => handleRemove(item)}>Rimuovi</button></td>
				</tr>
			)

			return {}
		})

		setViewdItems(data);
	}, [houseItems, disabled, setHouseItems]);

	return (
		<>
			<div className="d-flex flex-columns align-items-center">
				<label htmlFor="House" className="mb-2"><input disabled={disabled} onChange={() => setHaveHouse(!haveHouse)} type="checkbox" defaultChecked={haveHouse} name="House" id="House" /> <span className="mx-2"> Case?</span></label>
				{haveHouse && houseItems.length < 5 && !disabled ? <button onClick={() => setOpenAddHouse(!openAddHouse)} type="button" className="btn btn-primary ms-auto">Aggiungi Casa</button> : null}
			</div>

			{ openAddHouse ? <AddHouse setOpenAddHouse={setOpenAddHouse} handleSaveItems={handleSaveItems} /> : null}

			{ houseItems.length > 0 ?
				<div className="House-items">
					<hr />

					<table className="table">
						<thead>
							<tr>
								<th>Indirizzo</th>
								<th>Comune</th>
								<th>Città</th>
								<th>Metri Quadri</th>
								<th>Stato Immobile</th>
								<th>Valore</th>
								<th>Ipotetica</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{
								viewedItems
							}
						</tbody>
					</table>
				</div>
				: null}
		</>
	)
}

export default House;